<template>
  <component
    :is="url ? 'NuxtLink' : 'button'"
    :dir="row"
    :to="url"
    :style="`justify-content: ${
      between ? 'space-between' : 'center'
    }; align-items: center`"
    :class="[
      'r-btn',
      `r-btn-color-${color}`,
      `r-btn-size-${size}`,
      `${disabled ? 'disabled' : ''}`,
    ]"
    :disabled="disabled"
    v-bind="$attrs"
    :type="type"
  >
    <simple-preloader v-if="loading" />

    <template v-else>
      <span v-if="counter" class="counter">{{ counter }}</span>
      <!--      <slot name="icon" />-->
      <nuxt-icon
        v-if="icon"
        :name="icon"
        :class="[`${disabled ? 'sprite-static-sub-grey' : iconClass}`, `icon`]"
      />
      <slot />
    </template>
  </component>
</template>
<script>
import NuxtIcon from '@/libs/components/common/NuxtIcon.vue'
import SimplePreloader from '@/libs/ui_components/SimplePreloader/SimplePreloader.vue'

export default {
  name: 'RButton',
  components: {
    NuxtIcon,
    SimplePreloader,
  },
  props: {
    color: {
      type: String,
      default: 'blue',
      validator(value) {
        return [
          'transparent',
          'blue',
          'green',
          'yellow',
          'dark',
          'red',
          'grey',
          'sub-grey',
          'border-blue',
          'border-grеy',
          'all-blue',
          'transparent-blue',
          'transparent-grey',
          'border-white',
          'transparent-red',
        ].includes(value)
      },
    },
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return [
          'md',
          'lg',
          'xs',
          'xxs',
          'icon',
          'icon-xs',
          'full',
          'spice',
          'xs-padding',
          'height-24',
          'height-32',
        ].includes(value)
      },
    },
    row: {
      type: String,
      default: 'rtl',
    },
    between: {
      type: String,
      default() {
        return ''
      },
    },
    url: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default() {
        return ''
      },
    },
    iconClass: {
      type: String,
      default() {
        return ''
      },
    },
    class: {
      type: String,
      default() {
        return ''
      },
    },
    counter: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default() {
        return ''
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.r-btn {
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  padding: 4px 8px;
  gap: 4px;

  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}
.counter {
  position: absolute;
  top: -8px;
  left: -16px;
  width: 32px;
  height: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 0 4px;
  background-color: $main-green;
  border-radius: 16px;
  letter-spacing: 0.01em;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: $white;
}

.r-btn-color-blue {
  background-color: $main-blue;
  color: $white;
  border: none;

  .icon {
    stroke: $white;
  }

  &:hover {
    //box-shadow: $shadow-blue;
    background-color: $sub-blue;
    color: $white;

    .icon {
      stroke: $white;
    }
  }

  &:active {
    background-color: $light-blue;
    color: $white;

    .icon {
      stroke: $white;
    }
  }

  &.disabled {
    background-color: $sub-grey;
    color: $main-grey;
    pointer-events: none;

    .icon {
      stroke: $main-grey;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

.r-btn-color-green {
  background-color: $main-green;
  color: $white;
  border: none;

  .icon {
    stroke: $white;
  }

  &:hover {
    box-shadow: $shadow-green;
    background-color: $sub-green;
    color: $white;

    .icon {
      stroke: $white;
    }
  }

  &:active {
    background-color: $light-green;
    color: $white;

    .icon {
      stroke: $white;
    }
  }

  &.disabled {
    background-color: $sub-grey;
    color: $main-grey;
    pointer-events: none;

    .icon {
      stroke: $main-grey;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

.r-btn-color-yellow {
  background-color: $main-green;
  color: $white;
  border: none;

  .icon {
    stroke: $white;
  }

  &:hover {
    box-shadow: $shadow-green;
    background-color: $sub-green;
    color: $white;

    .icon {
      stroke: $white;
    }
  }

  &:active {
    background-color: $light-green;
    color: $white;

    .icon {
      stroke: $white;
    }
  }

  &.disabled {
    background-color: $sub-grey;
    color: $main-grey;
    pointer-events: none;

    .icon {
      stroke: $main-grey;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

.r-btn-color-red {
  background-color: $main-red;
  color: $white;
  border: none;

  .icon {
    stroke: $white;
  }

  &:hover {
    //box-shadow: $shadow-red;
    background-color: $sub-red;
    color: $white;

    .icon {
      stroke: $white;
    }
  }

  &:active {
    background-color: $light-red;
    color: $white;

    .icon {
      stroke: $white;
    }
  }

  &.disabled {
    background-color: $sub-grey;
    color: $main-grey;
    pointer-events: none;

    .icon {
      stroke: $main-grey;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

.r-btn-color-grey {
  background-color: $main-grey;
  color: $white;
  border: none;

  .icon {
    stroke: $white;
  }

  &:hover {
    background-color: $main-blue;
    color: $white;

    .icon {
      stroke: $white;
    }
  }
}

.r-btn-color-sub-grey {
  background-color: $sub-grey;
  border: none;
  stroke: $secondary-dark;

  &:hover {
    stroke: $main-dark;
    background-color: $main-grey;
  }

  &:active {
    background-color: $fivegray;
  }
}

.r-btn-color-dark {
  background-color: $main-dark;
  border: none;
  color: white;
}

.r-btn-color-border-blue {
  border: 1px solid $main-blue;
  color: $main-blue;
  background-color: transparent;

  .icon {
    stroke: $main-blue !important;
  }

  &:hover {
    box-shadow: $shadow-lvl-1;
    border: 1px solid $sub-blue;
    color: $sub-blue;

    .icon {
      stroke: $sub-blue;
    }
  }

  &:active {
    border: 1px solid $light-blue;
  }

  &.disabled {
    background-color: transparent;
    color: $main-grey;
    pointer-events: none;
    border: 1px solid $sub-grey;

    .icon {
      stroke: $main-grey;
    }
  }
}

.r-btn-color-border-grey {
  border: 1px solid $sub-dark;
  color: $sub-dark;
  background-color: transparent;

  .icon {
    stroke: $sub-dark;
  }

  &:hover {
    border: 1px solid $sub-blue;
    color: $sub-blue;
    background-color: transparent;

    .icon {
      stroke: $sub-blue;
    }
  }

  &:active {
    border: 1px solid $light-blue;
    background-color: transparent;
    color: $light-blue;

    .icon {
      stroke: $sub-blue;
    }
  }

  &.disabled {
    border: 1px solid $sub-grey;
    color: $main-grey;
    pointer-events: none;

    .icon {
      stroke: $main-grey;
    }
  }
}

.r-btn-color-transparent {
  background-color: transparent;
  border: none;

  // TODO: добавить ховеры

  // &:hover {
  //     border: none;
  //     color: $main-dark;
  //     background-color: transparent;
  //     .icon {
  //       stroke: $main-dark;
  //       width: 24px;
  //       height: 24px;
  //       transition: 0.3s;
  //       margin: 0;
  //     }
  //   }
  //   &:active {
  //     border: none;
  //     background-color: transparent;
  //     color: $secondary-dark;
  //     .icon {
  //       stroke: $secondary-dark;
  //     }
  //   }
}
.r-btn.r-btn-color-all-blue {
  border: none;
  background-color: transparent;
  stroke: $main-blue;
  padding: 0;
}
.r-btn.r-btn-color-transparent-blue {
  border: none;
  background-color: transparent;
  color: $main-blue;

  .icon {
    stroke: $sub-dark;
    transition: 0s;
    margin: 8px;
  }

  &:hover {
    border: none;
    color: $sub-blue;
    background-color: transparent;
    // text-shadow: $shadow-lvl-1;
    .icon {
      stroke: $sub-blue;
      transition: 0s;
    }
  }

  &:active {
    border: none;
    background-color: transparent;
    color: $light-blue;

    .icon {
      stroke: $light-blue;
    }
  }
}

.r-btn-color-transparent-grey {
  background-color: transparent;
  border: none;
  color: $secondary-dark;

  .icon {
    stroke: $secondary-dark;
    transition: 0s;
  }

  &:hover {
    border: none;
    color: $sub-blue;
    background-color: transparent;
    // text-shadow: $shadow-lvl-1;
    & .icon {
      stroke: $sub-blue;
      transition: 0s;
    }
  }

  &:active {
    border: none;
    background-color: transparent;
    color: $light-blue;

    .icon {
      stroke: $light-blue;
    }
  }
}

.r-btn-color-transparent-red {
  background-color: transparent;
  border: none;
  color: $secondary-dark;
  outline: none;

  .icon {
    stroke: $secondary-dark;
    transition: 0s;
  }

  &:hover {
    border: none;
    color: $main-red;
    background-color: transparent;
    // text-shadow: $shadow-lvl-1;
    .icon {
      stroke: $main-red;
      transition: 0s;
    }
  }

  &:active {
    border: none;
    background-color: transparent;
    color: $light-red;

    .icon {
      stroke: $light-red;
    }
  }
}

// button used in coockies for site
.r-btn-color-border-white {
  border: 1px solid $white;
  color: $white;
  background-color: transparent;

  .icon {
    stroke: $white;
    transition: 0s;
  }

  &:hover {
    border: 1px solid $main-dark;
    color: $main-dark;
    background-color: transparent;

    .icon {
      stroke: $main-dark;
      transition: 0s;
    }
  }

  &:active {
    border: 1px solid $secondary-dark;
    background-color: transparent;
    color: $secondary-dark;

    .icon {
      stroke: $secondary-dark;
    }
  }
}

//Sizes
.r-btn-size-lg {
  width: 176px;

  .icon {
    width: 24px;
    height: 24px;
  }
}

.r-btn-size-icon {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 24px;
    height: 24px;
    transition: 0s;
    margin: 0;
  }
}

.r-btn-size-icon-xs {
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 24px;
    height: 24px;
    transition: 0s;
    margin: 0;
  }
}

.r-btn-size-md {
  // width: 132px;
  padding: 8px 12px;
}

.r-btn-size-xs {
  padding: 0 16px;

  .icon {
    width: 24px;
    height: 24px;
  }
}

// TODO: можно совместить spice?
.r-btn-size-spice {
  width: 100%;
}

.r-btn-size-spice {
  padding: 0 16px;

  .icon {
    width: 24px;
    height: 24px;
  }
}

.r-btn-size-xxs {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  .icon {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.r-btn-size-full {
  width: 100%;
}

.r-btn-size-xs-padding {
  padding: 0 2px;
}

.r-btn-size-height-24 {
  height: 24px;
  gap: 4px;
  margin: 0;

  .icon {
    width: 24px;
    height: 24px;
  }
}

.r-btn-size-height-32 {
  height: 32px;
  gap: 4px;
  margin: 0;

  .icon {
    width: 24px;
    height: 24px;
  }
}
</style>
